<template>
  <v-card v-if="pendingVerification.type === 'page'" style="margin: 10px" min-width="350px" class="d-flex flex-column">

    <!-- Title -->
    <v-container text-center class="pa-0">
      <v-row>
        <v-col>
          <h2>Gedenkseite</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Avatar Photo -->
    <v-container class="pa-0" v-if="pendingVerification.page.pageSkeleton.profile_image">
      <v-row
        ><v-col align="center">
          <v-avatar size="120">
            <v-img
              :src="pendingVerification.page.pageSkeleton.profile_image"
            ></v-img>
          </v-avatar> </v-col
      ></v-row>
    </v-container>
    <v-container class="pa-0" v-else>
      <v-row
        ><v-col align="center">
          <v-avatar size="120">
            <v-icon size="120" color="orange">mdi-account-circle</v-icon>
          </v-avatar> </v-col
      ></v-row>
    </v-container>

    <!-- Page Data -->
    <v-container class="pa-0" style="margin-left: 30px; margin-right: 30px">
      <v-col>
        <v-row
          >Name:
          {{
            pendingVerification.page.pageSkeleton.first_name +
            " " +
            pendingVerification.page.pageSkeleton.last_name
          }}</v-row
        >
        <v-row>Geburtstag: {{ pendingVerification.page.pageSkeleton.birth }}</v-row>
        <v-row
          >Sterbedatum:
          {{
            new Date(pendingVerification.page.pageSkeleton.obit).toLocaleDateString("de-DE")
          }}</v-row
        >
        <v-row>Geburtsname: {{ pendingVerification.page.pageSkeleton.birth_name }}</v-row>
        <v-row>Title: {{ pendingVerification.page.pageSkeleton.title }}</v-row>
        <v-row>Ort: {{ pendingVerification.page.pageSkeleton.residence }}</v-row>
        <v-row>
          Erstellt am:
          {{
            new Date(pendingVerification.page.pageSkeleton.ct).toLocaleString("de-DE")
          }}
        </v-row>
        <v-row>
          Sichtbarkeit:
          {{
            pendingVerification.page.pageSkeleton.visibility === 'public' ? 'Öffentlich' : pendingVerification.page.pageSkeleton.visibility === 'protected' ? 'Nur mit Link' : 'Nur Seitenersteller'
          }}
        </v-row>
      </v-col>
    </v-container>

    <!-- User Data -->
    <v-container class="pa-0" style="margin-left: 30px; margin-right: 30px">
      <v-col>
        <v-row
          ><h3><u>User</u></h3></v-row
        >
        <v-row
          >Name:
          {{
            pendingVerification.user.prename +
            " " +
            pendingVerification.user.surname
          }}</v-row
        >
        <v-row>Mail: {{ pendingVerification.user.email }}</v-row>
        <v-row
          >Seiten:
          <ul id="pageList">
            <li v-for="page in pendingVerification.user.pages" :key="page">
              <a :href="urlPage + page" target="_blank">{{ page }}</a>
            </li>
          </ul>
        </v-row>
        <v-row>Verifiziert: {{ pendingVerification.user.isV }}</v-row>
      </v-col>
    </v-container>

    <v-spacer></v-spacer>

    <!-- Acction Buttons -->
    <v-card-actions>
      <v-container>

        <!-- Document Button -->
        <v-row v-if="fileExist">
          <v-col>
            <v-btn
              block
              color="orange"
              @click="goToPost(pendingVerification.page.verified.file, 'document')"
              >Dokument Ansehen</v-btn
            >
          </v-col>
        </v-row>

        <!-- See post button -->
        <v-row>
          <v-col>
            <v-btn
              block
              color="orange"
              @click="goToPost(pendingVerification.page.pageSkeleton.page_id)"
              @click.middle="goToPost(pendingVerification.page.pageSkeleton.page_id)"
              >Seite ansehen</v-btn
            >
          </v-col>
        </v-row>
        <v-row>

          <!-- Deny Button -->
          <v-col>
            <v-btn
              block
              color="red"
              @click="
                dialog = true;
                dialogText = 'Möchtest du die Seite wirklich ablehnen?';
                dialogStatus = false;
                greenBtnDialog = 'Ablehnen'
              "
              >Ablehnen</v-btn
            >
          </v-col>

          <!-- Accept Button -->
          <v-col>
            <v-btn
              block
              color="green"
              @click="
                dialog = true;
                dialogText = 'Möchtest du die Seite wirklich freigeben?';
                dialogStatus = true;
                greenBtnDialog = 'Freigeben'
              "
              >Freigeben</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>

    <!-- Sure verification popup -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="pa-4">
        <v-row class="pa-o ma-o">
          <v-col align="right" class="pa-0 ma-0">
            <v-icon @click="dialog = false" style="margin-right: 20px"> mdi-close</v-icon>
          </v-col>
        </v-row>

        <!-- Popup Text -->
        <v-row>
          <v-card-text align="center">
            {{ dialogText }}
          </v-card-text>
        </v-row>
        <v-row>

          <!-- Popup Deny Button -->
          <v-col>
            <v-btn block color="red" @click="dialog = false">Abbrechen</v-btn>
          </v-col>

          <!-- Popup Accept Button -->
          <v-col>
            <v-btn
              block
              color="green"
              @click="
                postPendings(dialogStatus);
                dialog = false;
              "
            >
              {{greenBtnDialog}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from 'axios'

export default {
  data () {
    return {
      urlPage: process.env.VUE_APP_SPA_LINK + '/Gedenkseite/',
      dialog: false,
      dialogText: 'asdf',
      dialogStatus: undefined,
      greenBtnDialog: '',
      fileExist: true
    }
  },
  props: ['pendingVerification'],
  methods: {
    postPendings (pendingStatus) {
      const vue = this
      axios
        .post('/verification/verify', {
          id: this.pendingVerification.id,
          status: pendingStatus
        })
        .then((res) => {
          vue.$emit('updatePendingsVerifications', res.data)
        })
    },
    goToPost (id, type) {
      let url = 'https://'
      if (type === 'document') {
        url = ''
      } else {
        url = this.urlPage
      }
      window.open(url + id)
    },
    checkFile () {
      let url
      try {
        url = new URL(this.pendingVerification.page.verified.file)
      } catch (_) {
        this.fileExist = false
      }
      return url || false
    }
  },
  mounted () {
    this.checkFile()
  },
  watch: {
    pendingVerification () {
      this.checkFile()
    }
  }
}
</script>
<style scoped>
#pageList {
  list-style-type: none;
}

#pageList a {
  text-decoration: none;
  color: black;
}
</style>
