<template>
  <v-container>
    <v-row class="px-6">
      <h1>Verification</h1>
      <v-spacer></v-spacer>
      <div>
        Aktualisieren
        <v-icon @click="loadPendingVerifications">
          mdi-reload
        </v-icon>
      </div>
    </v-row>

    <!-- Loading Screen -->
    <div style="height: 100%; width: 100%; text-align: center; padding-top: 200px" v-if="loading">
      <v-progress-circular
        size="100"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <div v-else>
      <v-row v-if="pendent_verifications.length > 0">
        <!-- Logical 'for' for add al the cards views -->
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="(pendingVerification, index) in pendent_verifications"
          :key="index"
        >
          <!-- Page view -->
          <verification-page-card
            v-if="pendingVerification.type === 'page'"
            :pendingVerification="pendingVerification"
            v-on:updatePendingsVerifications="update($event)"
            style="height: 100%"
          ></verification-page-card>
          <!-- Comment view -->
          <verification-comment-card
            v-if="pendingVerification.type === 'comment'"
            :pendingVerification="pendingVerification"
            v-on:updatePendingsVerifications="update($event)"
            style="height: 100%"
          ></verification-comment-card>
          <!-- Candle view -->
          <verification-candle-card
            v-if="pendingVerification.type === 'candle'"
            :pendingVerification="pendingVerification"
            v-on:updatePendingsVerifications="update($event)"
            style="height: 100%"
          ></verification-candle-card>
        </v-col>
      </v-row>

      <div style="width: 100%;" v-else>
        <div style="text-align: center; padding-top: 200px" class="display-1">
          <div class="mb-6">
            <v-icon size="200">$candle</v-icon>
          </div>
          Es sind keine Fälle verfügbar
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import VerificationPageCard from '@/components/verification/VerificationPageCard.vue'
import VerificationCommentCard from '@/components/verification/VerificationCommentCard.vue'
import VerificationCandleCard from '@/components/verification/VerificationCandleCard.vue'

import axios from 'axios'

export default {
  data () {
    return {
      pendent_verifications: [],
      loading: false
    }
  },
  components: {
    VerificationPageCard: VerificationPageCard,
    VerificationCommentCard: VerificationCommentCard,
    VerificationCandleCard: VerificationCandleCard
  },
  created () {
    this.loading = true
    this.loadPendingVerifications()
  },
  methods: {
    update: function (updatedPendings) {
      this.pendent_verifications = updatedPendings
    },
    loadPendingVerifications: function () {
      const vue = this
      axios.get('/verification/pending').then((res) => {
        vue.pendent_verifications = res.data
        vue.loading = false
      })
    }
  }
}
</script>
