<template>
  <v-card style="margin: 10px" min-width="350px" class="d-flex flex-column">
    <!-- Headline -->
    <v-container text-center class="pa-0">
      <v-row>
        <v-col>
          <h2>Kerze</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Content -->
    <v-container class="pa-0" style="margin-left: 30px; margin-right: 30px">
      <v-col>
        <v-row>Author: {{ pendingVerification.candle.by }}</v-row>
        <v-row>Text:</v-row>

        <!-- Message -->
        <v-card
          class="ma-1 pa-1" max-width="250px"
          elevation="1">
          {{ pendingVerification.candle.text }}
        </v-card>

        <v-row>Type:
          {{ pendingVerification.type === 'free' ? 'Wochenkerze'
          : pendingVerification.type === 'premium1' ? 'Jahreskerze'
          : pendingVerification.type === 'premium2' ? 'Monatskerze'
          : pendingVerification.type === 'premium3' ? 'premium3'
          : 'Ewige Kerze' }}
        </v-row>
        <v-row>Mail: {{ pendingVerification.candle.mail }}</v-row>
      </v-col>
    </v-container>

    <!-- Mockery Detections -->
    <v-container class="pa-0" style="margin-left: 30px; margin-right: 30px">
      <v-col>
        <v-row
          ><h3><u>Mockery Detection</u></h3></v-row
        >
        <v-row>Tag:
          {{ pendingVerification.mockery.tag === 'p' ? 'ausstehend'
          : pendingVerification.mockery.tag === 'mu' ? 'vom Nutzer gemeldet'
          : pendingVerification.mockery.tag === 'mki' ? 'automatisch gemeldet'
          : pendingVerification.mockery.tag === 'd' ? 'für gut befunden'
          : 'gelöscht' }}
        </v-row>
        <v-row>Score: {{ pendingVerification.mockery.score }}</v-row>
        <v-row>Grund: {{ pendingVerification.mockery.reason }}</v-row>
      </v-col>
    </v-container>

    <!-- Spacer -->
    <v-spacer></v-spacer>

    <!-- Buttons -->
    <v-card-actions>
      <v-container>
        <!-- View Page -->
        <v-row>
          <v-col>
            <v-btn
              block
              color="orange"
              @click="goToPost(pendingVerification.candle.page_id)"
              >Seite ansehen</v-btn
            >
          </v-col>
        </v-row>

        <!-- Delete -->
        <v-row>
          <v-col>
            <v-btn
              block
              color="red"
              @click="
                dialog = true;
                dialogText = 'Möchtest du die Kerze wirklich löschen?';
                dialogStatus = false;
                greenBtnDialog = 'Löschen'
              "
              >Löschen</v-btn
            >
          </v-col>

          <!-- Accept -->
          <v-col>
            <v-btn
              block
              color="green"
              @click="
                dialog = true;
                dialogText = 'Möchtest du die Kerze wirklich wiederherstellen?';
                dialogStatus = true;
                greenBtnDialog = 'Wiederherstellen'
              "
              >Wiederherstellen</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>

    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="pa-4">
        <v-row class="pa-o ma-o">
          <v-col align="right" class="pa-0 ma-0">
            <v-icon @click="dialog = false" style="margin-right: 20px"> mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-card-text align="center">
            {{ dialogText }}
          </v-card-text>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block color="red" @click="dialog = false">Abbrechen</v-btn>
          </v-col>
          <v-col>
            <v-btn
              block
              color="green"
              @click="
                postPendings(dialogStatus);
                dialog = false;
              "
            >
              {{greenBtnDialog}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from 'axios'

export default {
  data () {
    return {
      urlPage: 'https://sandbox.eternio.de/Gedenkseite/',
      dialog: false,
      dialogText: 'asdf',
      dialogStatus: undefined,
      greenBtnDialog: ''
    }
  },
  props: ['pendingVerification'],
  methods: {
    postPendings (pendingStatus) {
      const vue = this
      axios
        .post('/verification/verify', {
          id: this.pendingVerification.id,
          status: pendingStatus
        })
        .then((res) => {
          vue.$emit('updatePendingsVerifications', res.data)
        })
    },
    goToPost (id) {
      window.open(this.urlPage + id)
    }
  }
}
</script>
<style scoped>
#pageList {
  list-style-type: none;
}

#pageList a {
  text-decoration: none;
  color: black;
}
</style>
